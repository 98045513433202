<template>
  <el-dialog
    width="600px"
    :append-to-body="true"
    :before-close="closeHandle"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :visible.sync="visible"
    title="物料供应商信息"
  >
    <el-form :model="purchaseRow" label-position="right" label-width="200px">
      <el-form-item label="供应商名称:">{{purchaseRow.supplierName}}</el-form-item>
      <el-form-item label="供应商分类:">{{purchaseRow.supplierCategoryName}}</el-form-item>
      <el-form-item
        label="主营分类/品种:"
      >{{purchaseRow.supplierCategoryNameList && purchaseRow.supplierCategoryNameList.join()}}</el-form-item>
      <el-form-item label="联系人:">{{purchaseRow.supplierContactName}}</el-form-item>
      <el-form-item label="电话:">{{purchaseRow.supplierPhone}}</el-form-item>
      <el-form-item label="邮箱:">{{purchaseRow.email}}</el-form-item>
      <el-form-item label="微信:">{{purchaseRow.weChat}}</el-form-item>
      <el-form-item label="地址:">{{purchaseRow.supplierAddress}}</el-form-item>
    </el-form>
    <span class="dialog-footer" slot="footer">
      <el-button @click="closeHandle">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'bom-info-purchase-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 采购商信息
    purchaseRow: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    closeHandle () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
