<template>
  <div class="pattern-container">
    <div class="page-top">
      <div class="page-top--left">
        <SlImage :src="response.imageURL" />
      </div>
      <div class="page-top--right">
        <SlTableInfo :tableData="tableData" :columns="columns" headerAlign="left">
          <template #operation="{ row }">
            <el-button style="font-size: 12px;" type="text" @click="handleDownload(row)">下载纸样</el-button>
          </template>
        </SlTableInfo>
      </div>
    </div>
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import { downloadBlobData } from '@shared/util'
import axios from 'axios'
export default {
  name: 'Patterns',
  computed: {
    searchQuery () {
      const { designCode, patternVersion: version } = this.$route.query
      return {
        designCode,
        version
      }
    }
  },
  data () {
    return {
      columns: [
        {
          label: 'SPU',
          name: 'spuCode'
        }, {
          label: '设计款号',
          name: 'designCode'
        }, {
          label: '品类',
          name: 'categoryName'
        }, {
          label: '颜色',
          name: 'color'
        }, {
          label: '规格',
          name: 'specification'
        }, {
          label: '操作',
          name: 'operation'
        }
      ],
      response: {},
      tableData: []
    }
  },
  activated () {
    this.getData()
  },
  methods: {
    getData () {
      OemGoodsAPI.designPattern(this.searchQuery).then(res => {
        if (res.success) {
          this.response = res.data || {}
          this.tableData = (res.data && [res.data]) || []
        }
      })
    },
    handleDownload (row) {
      if (row.patternUrl) {
        axios({
          method: 'get',
          url: row.patternUrl,
          responseType: 'blob'
        }).then(inner => {
          downloadBlobData(new Blob([inner.data]), `${row.spuCode}.prj`)
        })
      } else {
        this.$message({
          type: 'warning',
          message: '纸样下载的链接无效！'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-top {
  display: flex;
  .page-top--left {
    flex: 0 1 auto;
    width: 180px;
    padding: 10px;
  }
  .page-top--right {
    flex: 1;
    padding-top: 10px;
  }
}
</style>
