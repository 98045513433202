<template>
  <div>
    <SlSwitchNav :navs="switchNavs" :default-active="activeComponent" @select="switchNav"></SlSwitchNav>
    <keep-alive>
      <component :is="activeComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import Bom from './productionMaterials/Bom.vue'
import Craft from './productionMaterials/Craft.vue'
import Patterns from './productionMaterials/Patterns.vue'
import Size from './productionMaterials/Size.vue'

export default {
  name: 'ProductionMaterials',
  components: {
    Bom,
    Craft,
    Patterns,
    Size
  },
  data () {
    return {
      activeComponent: 'Bom',
      switchNavs: [{
        tabName: 'BOM单',
        tabType: 'Bom'
      }, {
        tabName: '尺寸表',
        tabType: 'Size'
      }, {
        tabName: '工艺单',
        tabType: 'Craft'
      }, {
        tabName: '纸样',
        tabType: 'Patterns'
      }]
    }
  },
  mounted () {

  },
  methods: {
    switchNav (type) {
      this.activeComponent = type
    }
  }
}
</script>
