<template>
  <div class="craft-container">
    <el-card shadow="never">
      <div class="page-top">
        <div class="page-top--left">
          <SlImage :src="craftSkc.imageURL" />
        </div>
        <div class="page-top--right">
          <el-row :gutter="15">
            <el-col v-for="item of skcProps" :key="item.field" :span="6">
              <div style="line-height:2">
                <span class="color-text--primary mr-8px">{{item.label}}:</span>
                <span class="color-text--minor">{{craftSkc[item.field]}}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
    <el-card class="mt-2rem" shadow="never" v-for="craft of craftList" :key="craft.id">
      <el-form :model="craft" label-width="160px" class="craft-form">
        <el-form-item v-for="prop of listProps" :key="prop.field" :label="prop.label">
          <el-input
            disabled
            :autosize="{ minRows: 2, maxRows: 5}"
            class="color-text--minor"
            type="textarea"
            v-model="craft[prop.field]"
            placeholder
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
export default {
  name: 'Craft',
  computed: {
    searchQuery () {
      const { designCode, craftVersion: version } = this.$route.query
      return {
        designCode,
        version
      }
    }
  },
  data () {
    return {
      skcProps: [
        {
          label: 'SPU',
          field: 'spuCode'
        }, {
          label: '设计款号',
          field: 'designCode'
        }, {
          label: '品类',
          field: 'categoryName'
        }, {
          label: '工艺师',
          field: 'craftsmanName'
        }, {
          label: '颜色',
          field: 'color'
        }, {
          label: '规格',
          field: 'specification'
        }
      ],
      craftSkc: {},
      listProps: [
        {
          label: '款式品类:',
          field: 'styleCategoryName'
        }, {
          label: '整烫要求:',
          field: 'ironRequire'
        }, {
          label: '包装要求:',
          field: 'packageRequire'
        }, {
          label: '针、线要求:',
          field: 'needleworkRequire'
        }, {
          label: '唛头:',
          field: 'shippingMark'
        }, {
          label: '裁剪要求:',
          field: 'specialCraft'
        }, {
          label: '样衣工艺及注意事项:',
          field: 'attentions'
        }
      ],
      craftList: []
    }
  },
  activated () {
    this.getData()
  },
  methods: {
    getData () {
      OemGoodsAPI.designCraft(this.searchQuery).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.craftSkc = data.craftSkc || {}
          this.craftList = data.craftBillList || []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/_var.scss';
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: $color-text-minor;
}
.page-top {
  display: flex;
  .page-top--left {
    flex: 0 1 auto;
    width: 180px;
    padding: 10px;
  }
  .page-top--right {
    flex: 1;
    padding-top: 10px;
  }
}
/deep/ .craft-form {
  .el-form-item__label {
    color: $color-text-primary;
    line-height: 1.2;
  }
  .el-form-item__content {
    p {
      line-height: 1.2;
      word-break: break-all;
    }
  }
}
</style>
