<template>
  <div class="bom-container">
    <el-card shadow="never">
      <div class="page-top">
        <div class="page-top--left">
          <SlImage :src="bomSkc.imageURL" />
        </div>
        <div class="page-top--right">
          <el-row :gutter="15">
            <el-col v-for="item of skcProps" :key="item.field" :span="6">
              <div style="line-height:2">
                <span class="color-text--primary mr-8px">{{item.label}}:</span>
                <span class="color-text--minor">{{bomSkc[item.field]}}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
    <el-card class="mt-2rem" shadow="never" v-for="(material, index) of materialLists" :key="index">
      <SlContentTitle :text="switchType(index)" :fontSize="titleFontSize" :line="false" columnIcon></SlContentTitle>
      <SlTableInfo
        :isEmbedTable="true"
        :tableData="material"
        :columns="getColumns(index)"
        headerAlign="left"
      >
        <template #material="{row, index}">{{row.item}}</template>
        <template #materialInfo=" {row} ">
          <div>
            <span class="color-text--primary mr-8px">名称：</span>
            <span class="color-text--minor">{{row.materialName}}</span>
          </div>
          <div>
            <span class="color-text--primary mr-8px">单位：</span>
            <span class="color-text--minor">{{row.unitName}}</span>
          </div>
          <template v-if="row.type === 0">
            <div>
              <span class="color-text--primary mr-8px">成分：</span>
              <span class="color-text--minor">{{getComposition(row.composition)}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">门幅(CM)：</span>
              <span class="color-text--minor">{{row.larghezzaWidth}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">克重(G)：</span>
              <span class="color-text--minor">{{row.gramWeight}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">弹性：</span>
              <span class="color-text--minor">{{row.elasticityCategoryName}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">分类：</span>
              <span class="color-text--minor">{{row.secondCategoryName}}</span>
            </div>
          </template>
          <template v-else>
            <div>
              <span class="color-text--primary mr-8px">规格：</span>
              <span class="color-text--minor">{{row.specification}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">材质：</span>
              <span class="color-text--minor">{{row.subsidiaryIngredientName}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">形状：</span>
              <span class="color-text--minor">{{row.subsidiaryShapeName}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">宽度(CM)：</span>
              <span class="color-text--minor">{{row.width}}</span>
            </div>
          </template>
        </template>
        <template #supplierInfo="{ row }">
          <div>
            <span class="color-text--primary mr-8px">供应商：</span>
            <el-button type="text" @click="openSupplierDetail(row)">{{row.simpleSupplierName}}</el-button>
          </div>
          <div>
            <span class="color-text--primary mr-8px">供应商物料名称：</span>
            <span class="color-text--minor">{{row.supplierMaterialName}}</span>
          </div>
          <template v-if="row.type === 0">
            <div>
              <span class="color-text--primary mr-8px">足米价：</span>
              <span class="color-text--minor">{{row.price}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">颜色：</span>
              <span class="color-text--minor">{{row.fabricColorName}}</span>
            </div>
            <div>
              <span class="color-text--primary mr-8px">供应商色号：</span>
              <span class="color-text--minor">{{row.supplierSequence}}</span>
            </div>
          </template>
          <template v-else>
            <div>
              <span class="color-text--primary mr-8px">单价：</span>
              <span class="color-text--minor">{{row.price}}</span>
            </div>
          </template>
        </template>
        <template #purchaseImages="{ row }">
          <SlImage size="44px" :src="url" v-for="url of row.purchaseImages" :key="url" />
        </template>
      </SlTableInfo>
    </el-card>
    <!-- 供应商详情 Dialog -->
    <SupplierInfoDialog :visible.sync="visible" :purchaseRow="purchaseRow" />
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import SupplierInfoDialog from './SupplierInfoDialog'
export default {
  name: 'Bom',
  components: {
    SupplierInfoDialog
  },
  computed: {
    searchQuery () {
      const { designCode, bomVersion: version } = this.$route.query
      return {
        designCode,
        version
      }
    },
    materialLists () {
      const { materialList0, materialList1 } = this
      return [materialList0, materialList1]
    }
  },
  data () {
    return {
      titleFontSize: '1.6rem',
      skcProps: [
        {
          label: 'SPU',
          field: 'spuCode'
        }, {
          label: '设计款号',
          field: 'designCode'
        }, {
          label: '颜色',
          field: 'color'
        }, {
          label: '规格',
          field: 'specification'
        }
      ],
      bomSkc: {},
      listProps: [
        {
          label: '款式品类',
          field: 'styleCategoryName'
        }, {
          label: '整烫要求',
          field: 'ironRequire'
        }, {
          label: '包装要求',
          field: 'packageRequire'
        }, {
          label: '针、线要求',
          field: 'needleworkRequire'
        }, {
          label: '唛头',
          field: 'shippingMark'
        }, {
          label: '裁剪要求',
          field: 'specialCraft'
        }, {
          label: '样衣工艺及注意事项',
          field: 'attentions'
        }
      ],
      // 面料
      materialList0: [],
      materialType0: [
        {
          label: '物料项目',
          name: 'material',
          width: '110px'
        }, {
          label: '物料SKU',
          name: 'materialSkuCode'
        }, {
          label: '单件用量',
          name: 'singleAmount',
          width: '110px'
        }, {
          label: '使用部位',
          name: 'partUsedName',
          width: '120px'
        }, {
          label: '物料信息',
          name: 'materialInfo',
          minWidth: '110px'
        }, {
          label: '供应商信息',
          name: 'supplierInfo',
          minWidth: '160px'
        }, {
          label: '二次工艺',
          name: 'secondaryCraftNames'
        }, {
          label: '裁剪方法',
          name: 'tailorModeName',
          width: '110px'
        }, {
          label: '图片',
          name: 'purchaseImages',
          width: '200px'
        }
      ],
      // 辅料
      materialList1: [],
      materialType1: [
        {
          label: '物料项目',
          name: 'material',
          width: '110px'
        }, {
          label: '物料SKU',
          name: 'materialSkuCode'
        }, {
          label: '辅料颜色',
          name: 'auxiliaryColorName',
          width: '110px'
        }, {
          label: '单件用量',
          name: 'singleAmount',
          width: '110px'
        }, {
          label: '使用部位',
          name: 'partUsedName',
          width: '120px'
        }, {
          label: '物料信息',
          name: 'materialInfo',
          minWidth: '110px'
        }, {
          label: '供应商信息',
          name: 'supplierInfo',
          minWidth: '160px'
        }, {
          label: '二次工艺',
          name: 'secondaryCraftNames'
        }, {
          label: '图片',
          name: 'purchaseImages',
          width: '200px'
        }
      ],
      visible: false,
      purchaseRow: {}
    }
  },
  activated () {
    this.getData()
  },
  methods: {
    getData () {
      OemGoodsAPI.designBom(this.searchQuery).then(res => {
        if (res.success) {
          const data = res.data || {} // 查询无数据，data为null
          this.bomSkc = data.skc || {}
          const materialList = data.materialList || []
          this.materialList0 = materialList.filter(item => item.type === 0)
          this.materialList1 = materialList.filter(item => item.type === 1)
        }
      })
    },
    openSupplierDetail (row) {
      this.visible = true
      this.purchaseRow = row // 文件来源 erp- src/pdmModule/views/product/schedule/components/printCraft/index.vue
    },
    switchType (type) {
      const _enum = {
        0: '面料',
        1: '辅料'
      }
      return _enum[type] || '-'
    },
    getComposition (data) {
      try {
        data = JSON.parse(data)
        return data.map(item => `${item.compositionName}:${item.percentage}%`).join(',')
      } catch (e) {
        return ''
      }
    },
    getColumns (type) {
      return this['materialType' + type]
    }
  }
}
</script>

<style lang="scss" scoped>
.page-top {
  display: flex;
  .page-top--left {
    flex: 0 1 auto;
    width: 180px;
    padding: 10px;
  }
  .page-top--right {
    flex: 1;
    padding-top: 10px;
  }
}
</style>
