<template>
  <div class="size-container">
    <el-card shadow="never">
      <div class="page-top">
        <div class="page-top--left">
          <SlImage :src="sizeSkc.imageURL" />
        </div>
        <div class="page-top--right">
          <el-row :gutter="15">
            <el-col v-for="item of skcProps" :key="item.field" :span="6">
              <div style="line-height:2">
                <span class="color-text--primary mr-8px">{{item.label}}:</span>
                <span class="color-text--minor">{{sizeSkc[item.field]}}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
    <el-card
      class="mt-2rem"
      shadow="never"
      v-for="category of tableDatas"
      :key="category.styleCategoryId"
    >
      <div style="line-height:2.2">
        <span class="color-text--primary mr-8px">款式品类:</span>
        <span class="color-text--minor">{{category.styleCategoryName}}</span>
      </div>
      <SlTableInfo
        :isEmbedTable="true"
        :tableData="category.children"
        :columns="columns"
        headerAlign="left"
      >
        <template #tolerance="{row}">&#177;{{ row.tolerance}}</template>
      </SlTableInfo>
    </el-card>
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
const commonColumn = [
  {
    label: 'ERP部位',
    name: 'plmAttributeTermName'
  },
  {
    label: '部位',
    name: 'sizePartName'
  },
  {
    label: '量法',
    name: 'measurement'
  },
  {
    label: '公差',
    name: 'tolerance'
  }
]
export default {
  name: 'Size',
  computed: {
    searchQuery () {
      const { designCode, sizeVersion: version } = this.$route.query
      return {
        designCode,
        version
      }
    }
  },
  data () {
    return {
      // 头部信息
      skcProps: [
        {
          label: 'SPU',
          field: 'spuCode'
        }, {
          label: '设计款号',
          field: 'designCode'
        }, {
          label: '品类',
          field: 'categoryName'
        }, {
          label: '尺码类型',
          field: 'sizeCategoryName'
        }, {
          label: '颜色',
          field: 'color'
        }, {
          label: '规格',
          field: 'specification'
        }, {
          label: '放码师',
          field: 'apparelGradingName'
        }
      ],
      sizeSkc: {},
      columns: commonColumn.slice(),
      tableDatas: []
    }
  },
  activated () {
    this.getData()
  },
  methods: {
    getData () {
      OemGoodsAPI.designSize(this.searchQuery).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.sizeSkc = data.sizeSkc
          this.columns = commonColumn.slice()
          this.initTableData(data.children)
        }
      })
    },
    // 处理表头数据columns + 扁平化尺码表sizeCodes
    initTableData (data) {
      if (data.length) {
        let flatSizeCodes = false
        data.forEach(item => {
          const children = item.children || []
          if (children.length) {
            children.forEach(size => {
              const sizeCodes = size.sizeCodes || []
              if (sizeCodes.length) {
                // 动态获取尺码表信息，添加到 columns
                if (!flatSizeCodes) {
                  const extraColumns = sizeCodes.map(size => ({
                    label: size.sizeName,
                    name: size.sizeName
                  }))
                  extraColumns.length && this.columns.splice(this.columns.length - 1, 0, ...extraColumns)
                  flatSizeCodes = true
                }
                // 扁平化 sizeCodes 尺码表
                sizeCodes.forEach(code => {
                  size[code.sizeName] = code.value
                })
              }
            })
          }
        })
      }
      this.tableDatas = data
    }
  }
}
</script>

<style lang="scss" scoped>
.page-top {
  display: flex;
  .page-top--left {
    flex: 0 1 auto;
    width: 180px;
    padding: 10px;
  }
  .page-top--right {
    flex: 1;
    padding-top: 10px;
  }
}
</style>
