var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bom-container"},[_c('el-card',{attrs:{"shadow":"never"}},[_c('div',{staticClass:"page-top"},[_c('div',{staticClass:"page-top--left"},[_c('SlImage',{attrs:{"src":_vm.bomSkc.imageURL}})],1),_c('div',{staticClass:"page-top--right"},[_c('el-row',{attrs:{"gutter":15}},_vm._l((_vm.skcProps),function(item){return _c('el-col',{key:item.field,attrs:{"span":6}},[_c('div',{staticStyle:{"line-height":"2"}},[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v(_vm._s(item.label)+":")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(_vm.bomSkc[item.field]))])])])}),1)],1)])]),_vm._l((_vm.materialLists),function(material,index){return _c('el-card',{key:index,staticClass:"mt-2rem",attrs:{"shadow":"never"}},[_c('SlContentTitle',{attrs:{"text":_vm.switchType(index),"fontSize":_vm.titleFontSize,"line":false,"columnIcon":""}}),_c('SlTableInfo',{attrs:{"isEmbedTable":true,"tableData":material,"columns":_vm.getColumns(index),"headerAlign":"left"},scopedSlots:_vm._u([{key:"material",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_vm._v(_vm._s(row.item))]}},{key:"materialInfo",fn:function( ref ){
var row = ref.row;
return [_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("名称：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.materialName))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("单位：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.unitName))])]),(row.type === 0)?[_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("成分：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(_vm.getComposition(row.composition)))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("门幅(CM)：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.larghezzaWidth))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("克重(G)：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.gramWeight))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("弹性：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.elasticityCategoryName))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("分类：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.secondCategoryName))])])]:[_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("规格：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.specification))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("材质：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.subsidiaryIngredientName))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("形状：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.subsidiaryShapeName))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("宽度(CM)：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.width))])])]]}},{key:"supplierInfo",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("供应商：")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.openSupplierDetail(row)}}},[_vm._v(_vm._s(row.simpleSupplierName))])],1),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("供应商物料名称：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.supplierMaterialName))])]),(row.type === 0)?[_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("足米价：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.price))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("颜色：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.fabricColorName))])]),_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("供应商色号：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.supplierSequence))])])]:[_c('div',[_c('span',{staticClass:"color-text--primary mr-8px"},[_vm._v("单价：")]),_c('span',{staticClass:"color-text--minor"},[_vm._v(_vm._s(row.price))])])]]}},{key:"purchaseImages",fn:function(ref){
var row = ref.row;
return _vm._l((row.purchaseImages),function(url){return _c('SlImage',{key:url,attrs:{"size":"44px","src":url}})})}}],null,true)})],1)}),_c('SupplierInfoDialog',{attrs:{"visible":_vm.visible,"purchaseRow":_vm.purchaseRow},on:{"update:visible":function($event){_vm.visible=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }